import React, { useContext, useEffect } from "react";
import { ARTIST_LAUYOUT } from "../context/consts";
import { GlobalDispatchContext } from "../context/GlobalContextProvider"
import FloatingMagicNavigator from "../components/FloatingMagicNavigator";
import PageLayout from '../components/PageLayout';
import * as styles from './artist.module.css'
import GridFramedPicture from "../components/GridFramedPicture";
import { StaticImage } from "gatsby-plugin-image";
import Seo from "../components/Seo";


const ArtistPage = () => {

    const dispatch = useContext(GlobalDispatchContext);
    useEffect(() => dispatch({ type: "TOGGLE_THEME", theme: ARTIST_LAUYOUT }), [dispatch])

    return (
        <PageLayout withFooter={false} withTitle={false}>
            <div className={styles.artistGrid}>
                <GridFramedPicture header="dotworks" linkto="/artist/dotworks">
                    <StaticImage src="./../images/artist/frame_dotworks.jpg" layout="fullWidth" alt=""/>
                </GridFramedPicture>
                <GridFramedPicture header="calligraphy" linkto="/artist/calligraphy">
                    <StaticImage src="./../images/artist/frame_calligraphy.jpg" layout="fullWidth" alt=""/>
                </GridFramedPicture>
                <GridFramedPicture header="conceptart" linkto="/artist/conceptart">
                    <StaticImage src="./../images/artist/frame_conceptart.jpg" layout="fullWidth" alt=""/>
                </GridFramedPicture>
            </div>
            <FloatingMagicNavigator />
            <Seo subTitle="Artist" />
        </PageLayout>
    )
}

export default ArtistPage