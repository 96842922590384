import { Link } from 'gatsby';
import React from 'react'
import BorderedLabel from './BorderedLabel';
import * as styles from './GridFramedPicture.module.css'


const GridFramedPicture = ({ header, linkto, children }) => {

    return (
        <div className={styles.frameGridElement}>
            <Link className={styles.imgContainer} to={linkto}>
                {children}
            </Link>

            <Link className={styles.titleContainer} to={linkto}>
                <div className={styles.linkHeader}>
                    <BorderedLabel firstLabel="" secondLabel={header} />
                </div>
            </Link>
        </div>
    )
}

export default GridFramedPicture;